:root {
  --primary-green-color: #0A4C3E;;
  --white-color: #ffffff;
  --fade-green-color: #90f7a7;
  --menu-color: #011c14;
  --fade-menu-color: #282929;
}

@import "react-toastify/dist/ReactToastify.css";
@import "antd/dist/antd.css";
@import "./Auth.scss";
@import "aos/dist/aos.css";
@import "./Layout.scss";
@import "./Admin.scss";
@import "react-big-calendar/lib/css/react-big-calendar.css";
// menu override

// S I D E - D R A W E R

@media (min-width: 600px) {
  .makeStyles-drawerClose-18 {
    width: 57px;
  }
  .makeStyles-toolbar-179 {
    min-height: 52px !important;
  }
}

.card-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  transition: ease all 0.5s;
}

.tableaction {
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    font-size: 12px;
    svg {
      font-size: 20px;
    }
    &:first-child {
      svg {
        fill: var(--primary-green-color);
      }
    }
    &:nth-child(4) {
      svg {
        fill: #eb5757;
      }
    }
    &:last-child {
      svg {
        fill: red;
      }
    }
    &.team {
      svg {
        fill: var(--primary-green-color);
      }
    }
  }
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      padding-right: 4px;
    }
  }
}

.ant-picker-input > input {
  color: #fff;
}
.ant-picker-separator {
  color: #fff;
}
.ant-picker-range-separator {
  line-height: 0;
}
.notask {
  display: flex;
  padding: 10px;
  justify-content: center;
  background: #0c6a4e;
  width: 30%;
  margin: 10px auto;
  border-radius: 4px;
  color: #fff;
  font-size: 1.1rem;
}
.example {
  margin: 20px auto;
  padding: 0;
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 32%;
  justify-content: center;
  align-items: center;
  transition: ease all 0.5s;
  h5 {
    font-weight: bold;
    font-size: 1.2rem;
  }
  svg {
    transition: ease all 0.5s;
    transform: rotate(0deg);
  }
  .ant-btn-dashed {
    margin-top: 2rem;
    &:hover {
      svg {
        transition: ease all 0.5s;
        transform: rotate(180deg);
      }
    }
  }
}

.toCap {
  text-transform: capitalize;
}
.toUpper {
  text-transform: uppercase;
}

.dateTable {
  margin-bottom: 0;
  line-height: 10px;
}

.paymentfirst {
  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .item {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    &.total {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
  button {
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 40px;
    margin: 1rem auto;
    padding: 10px 1rem;
    justify-content: center;
    transition: ease all 0.5s;
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    color: var(--white-color);
  }
  .btn-cancel {
    background: #dddddd;
    border-color: #dddddd;
    color: #000;
  }
}