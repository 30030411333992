/* .App {
    text-align: center;
} */

.leaflet-control-layers-toggle {
    width: 3.5em !important;
    height: 3.5em !important;
    right: 3em !important;
    margin-top: 1.3em !important;
    background-color: white;
    border-radius: 0.3em;
    position: absolute;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -ms-border-radius: 0.3em;
    -o-border-radius: 0.3em;
}

.leaflet-control-layers-expanded {
    width: 15em !important;
    height: fit-content !important;
    line-height: 2em;
    margin-top: 2em !important;
    z-index: 100000000000000000000000000000000;
}

.leaflet-control-zoom {
    /* margin-top: 2em !important;
    position: fixed !important;
    margin-left: 90% !important;
    right: 8em !important;
    width: 3.7em !important;
    background-color: white !important;
    padding: 0.1em 0.3em;
    float: right !important; */
    margin-top: 10em !important;
    position: relative !important;
    float: left !important;
    margin-left: 2em !important;
    /* width: 3em !important; */
    border: none !important;
    background-color: white !important;
    /* padding: 0.1em 0.5em; */
}

.tooltip {
    color: white !important;
    background: rgba(1, 28, 20, 1) !important;
    border: 1px solid rgba(1, 28, 20, 1) !important;
}

.leaflet-popup-tip,
.leaflet-popup-content-wrapper {
    color: white !important;
    background-color: rgba(1, 28, 20, 0.9) !important;
    font-weight: bold !important;
    border: 1px solid rgba(1, 28, 20, 1) !important;
}


/* leaflet-bar */

.leaflet-control-geosearch {
    margin-top: -11em !important;
    float: left !important;
    margin-left: 2em !important;
    height: 4em !important;
    /* border: 1px solid rgba(1, 28, 20, 1) !important; */
    color: rgba(1, 28, 20, 1) !important;
    outline: none !important;
    background-color: white;
    border: none !important;
}

.leaflet-control-geosearch input {
    height: 3.7em !important;
    color: rgba(1, 28, 20, 1) !important;
    outline: none !important;
    border-radius: 0.5em !important;
    -webkit-border-radius: 0.5em !important;
    -moz-border-radius: 0.5em !important;
    -ms-border-radius: 0.5em !important;
    -o-border-radius: 0.5em !important;
}

@media screen and (max-width:769px) {
    .leaflet-control-layers-toggle {
        width: 3.5em !important;
        height: 3.5em !important;
        right: 3em !important;
        margin-top: 1.9em !important;
    }
}

.active {
    /* color: red; */
    color: white;
    text-decoration: white underline;
}

.activeMobile {
    color: rgba(5, 103, 74, 1);
    text-decoration: rgba(5, 103, 74, 1) underline;
}

.ant-picker-dropdown {
    z-index: 1000000000000000000000000000000000000;
    /* added a large random number, you can choose it more carefully based on your use-case.*/
}