.left-side {
  height: 100vh;
  background: rgb(120, 201, 94);
  width: auto;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 50%;
  transition: ease all 0.5s;
  @media screen and (max-width: 499px) {
    left: 0;
    right: 0;
  }
  .left-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    padding: 3rem;
    position: relative;
    color: var(--white-color);
    top: 10rem;
    h2 {
      font-weight: bold;
      color: var(--white-color);
      font-size: 2.4em;
      text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    p {
      text-align: center;
    }
  }
  .overlay {
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.card-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
}

.auth-title {
  transition: ease all 0.5s;
  h2 {
    color: rgba(0, 106, 75, 1);
    font-weight: bold;
    font-size: 2rem;
  }
}

.right-side {
  position: relative;
  z-index: 1;
  overflow: auto;
  transition: ease all 0.5s;
  display: flex;
  align-items: center;
  height: 100%;
  top: 40px;
  transition: ease all 0.5s;
  .auth-form {
    position: relative;
    padding: 2rem 6rem;
    transition: ease all 0.5s;
    a {
      color: var(--primary-green-color);
      font-weight: bold;
      text-decoration: none;
    }
    label {
      display: flex;
    }
    .p-pwd {
      text-align: right;
    }
    .register {
      text-align: center;
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        svg {
          transition: ease all 0.5s;
          transform: translateX(0);
          transform: rotate(180deg);
        }
        &:hover svg {
          transition: ease all 0.5s;
          transform: translateX(-10px);
        }
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #05674a;
      border-color: #05674a;
    }
    ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #05674a;
    }
    @media screen and (max-width: 499px) {
      padding: 3rem 1rem;
    }
    .auth_page_writeup {
      h2 {
        color: rgba(0, 106, 75, 1);
        font-size: 1.62rem;
        font-weight: bold;
      }
      p {
        font-size: 0.7rem;
        margin-bottom: 1.3rem;
      }
    }
    .ant-btn-primary {
      background: var(--primary-green-color);
      border-color: var(--primary-green-color);
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      &:hover {
        background: #044230;
        border-color: #044230;
      }
    }
    .ant-input {
      height: 40px;
      border-radius: 4px;
    }
    .ant-input-affix-wrapper {
      overflow: hidden;
      height: 43px;
    }
    input[type="password"] {
      height: auto;
      border: none;
      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
        border-color: transparent;
      }
    }
    .g-login {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px solid #ccc;
      height: 40px;
      border-radius: 4px;
      background: var(--white-color);
      width: 100%;
      outline: none;
      padding: 0 20px;
      margin-bottom: 15px;
      img {
        padding-left: 10px;
        transition: ease all 0.5s;
        transform: translateX(0);
      }
      span {
        font-size: 1rem;
        font-weight: 500;
        padding-left: 4rem;
      }
      &:hover {
        transition: ease all 0.5s;
        img {
          transition: ease all 0.5s;
          transform: translateX(10px);
        }
      }
    }
  }
  .ant-card-body {
    padding: 2.4rem 3rem;
  }
  @media screen and (max-width: 499px) {
    z-index: 200;
    .ant-card-body {
      padding: 1rem 1.3rem;
    }
  }
}

.validate_token {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  transition: ease all 0.5s;
  margin: 15px 0;
  border-radius: 4px;
  background: var(--fade-green-color);
  color: var(--primary-green-color);
  h4 {
    padding-left: 20px;
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  &.error {
    background: #fb8460;
    color: #491302;
  }
}
.form-group {
  margin-bottom: 15px;
}

.emailsent {
  width: 100%;
  //   height: 100vh;
  background: var(--white-color);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 100;
  &__message {
    width: inherit;
    margin: auto;
    text-align: center;
    padding: 1rem;
    transition: ease all 0.5s;
    animation: zoomIn 1s 1s;
    .icon {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 2rem 0;
      transition: ease all 0.5s;
      svg {
        font-size: 4rem;
        fill: var(--primary-green-color);
      }
    }
    .ant-btn-primary {
      height: 40px;
      font-family: "Montserrat", sans-serif;
      background: var(--primary-green-color);
      border-color: var(--primary-green-color);
      border-radius: 0;
      animation: 1s ease-out 2s 1 slideInUp;
      a {
         color: var(--white-color);
      }
    }
    h3 {
      color: var(--primary-green-color);
      font-size: 1.6rem;
      margin: 0;
    }
    h4 {
      color: #000;
      font-size: 0.81rem;
      font-weight: 400;
      margin-bottom: 3rem;
      padding-top: 10px;
    }
  }
}
