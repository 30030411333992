.h_100vh {
    height: 100vh;
}

.w_90 {
    width: 90%;
}

.farm_card_img_wrapper {
    height: 90px;
}

.farm_card_wrapper {
    background: #FAFAFA;
    height: 51vh;
    overflow-y: scroll;
}

.map_wrapper {
    /* height: 100vh; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}


/* .cordinate_Wrapper {
    max-height: ;
} */

.sideBar_content_wrapper {
    position: absolute;
    top: 1em;
    bottom: 1em;
    right: 1em;
    left: 1em;
}

.map_img {
    object-fit: none;
}

.map_filter_wrapper {
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 25px;
    right: 25px;
}

.add_field_type_cards {
    background: #FAFAFA;
    border-radius: 4px;
    /* height: 365px; */
    height: fit-content;
    margin-bottom: 3em;
}

.file_support_text {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(1, 28, 20, 0.74);
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
}

.addField_sub_card_headers {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #011C14;
}

.dragAndDrop {
    outline: none !important;
    margin-top: 1em !important;
}

.dragAndDrop:hover {
    background-color: rgba(1, 28, 20, 0.1) !important;
    outline: none !important;
}

.dragAndDropText {
    margin-top: 4em !important;
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 3em !important;
    text-align: center !important;
    letter-spacing: 0.02em !important;
    color: rgba(1, 28, 20, 0.74) !important;
}

.addField_sub_card_p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(1, 28, 20, 0.74);
}

.close_modal_icon {
    position: absolute;
    right: 15px;
}

.addField_modal_header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #011C14;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.form_footer_ {
    margin-top: 100px;
}

.add_season_span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #05674A;
}

.delete_filed {
    position: absolute;
    right: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #05674A;
}

.remove_cordenate {
    position: absolute;
    right: 0;
    top: 10px;
}

.del_field_icon {
    color: #EB5757;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 767px) {
    .map_wrapper {
        height: 100vh;
        position: unset;
        width: 100%;
    }
}