.aside {
  position: fixed;
  transition: ease all 0.6s;
  height: 100%;
  width: 220px;
  background: var(--white-color);
  z-index: 99;
  transform: translateX(0);
  overflow-y: auto;
  &.open {
    transform: translateX(0);
    transition: ease all 0.6s;
  }
  .profile {
    padding: 3.4rem 15px 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ease all 0.6s;
    &__avatar {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      background: var(--primary-green-color);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      span {
        color: var(--white-color);
        font-size: 2rem;
        font-weight: bold;
      }
    }
    h4 {
      text-transform: capitalize;
      margin-bottom: 0;
      font-size: 1.1rem;
    }
    p {
      margin-bottom: 10px;
      font-size: 1rem;
      text-transform: capitalize;
    }
    button {
      background: var(--primary-green-color);
      color: var(--white-color);
      height: 45px;
      border: none;
      font-size: 1.1rem;
    }
    .admin {
      background: var(--primary-green-color);
    }
  }
  .sidemenu {
    transition: ease all 0.5s;
    position: relative;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li a {
      transition: ease all 0.5s;
      display: flex;
      align-items: center;
      padding: 10px 1rem;
      margin-bottom: 10px;
      color: var(--fade-black);
      position: relative;
      font-size: 0.9rem;
      span {
        padding-left: 1rem;
      }
      svg {
        fill: var(--black-color);
        stroke: var(--black-color);
        padding: 5px;
      }
      &:after {
        position: absolute;
        content: "";
        right: 0;
        height: 100%;
        border: 2px solid #000;
        opacity: 0;
        transform: translateX(5px);
        visibility: hidden;
      }
      &:hover {
        color: var(--primary-green-color);
        &:after {
          transform: translateX(-2px);
          transition: ease all 0.5s;
          opacity: 1;
          visibility: visible;
        }
        svg {
          fill: var(--primary-green-color);
          stroke: var(--primary-green-color);
        }
      }
    }
    .activelink {
      position: relative;
      border-right: 4px solid var(--primary-green-color);
      li a {
        color: var(--primary-green-color);
        svg {
          fill: var(--primary-green-color);
          stroke: var(--primary-green-color);
        }
      }
    }
  }
  .lowermenu {
    transition: ease all 0.5s;
    position: relative;
    border-top: 1px solid #ddd;
    padding-top: 1rem;
    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
    }
    li {
      transition: ease all 0.5s;
      display: flex;
      align-items: center;
      padding: 2px 1rem;
      cursor: pointer;
      span {
        padding-left: 1rem;
      }
    }
  }
  @media (min-width: 449px) {
    .closedrawer {
      display: none;
    }
  }
  @media (max-width: 449px) {
    transform: translateX(-16rem);
    transition: ease all 0.6s;
    .closedrawer {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      transition: ease all 0.5s;
      transform: translateX(0);
      &:hover {
        transform: translateX(10px);
        transition: ease all 0.5s;
      }
    }
  }
}

.actiontable {
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    font-size: 14px;
  }
  .eid {
    svg {
      fill: green;
      stroke: green;
    }
  }
  .del {
    svg {
      fill: red;
      stroke: red;
    }
  }
}

.ant-btn-primary {
  background: var(--primary-green-color);
  border-color: var(--primary-green-color);
  //display: flex;
  align-items: center;
  &:hover {
    background: #04584d;
    border-color: #04584d;
  }
  &:active {
    background: #04584d;
    border-color: #04584d;
  }
  &:focus {
    background: #04584d;
    border-color: #04584d;
  }
  &.reduce-height {
    height: 35px;
  }
}

.ant-table-tbody {
  //text-transform: capitalize;
}