.MuiAppBar-colorPrimary {
  background-color: var(--white-color) !important;
}
.MuiAppBar-root {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 0;
  transition: ease all 0.5s;
}
.MuiPaper-elevation4 {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06) !important;
}
.profile {
  transition: ease all 0.5s;
  margin-right: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  &__avatar {
    width: 40px;
    height: 40px;
    background: #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    span {
      font-weight: bold;
      color: var(--primary-green-color);
      font-size: 1rem;
    }
  }
  &__name {
    margin-left: 5px;
    color: var(--primary-green-color);
    font-size: small;
  }
  &__icon {
    svg {
      fill: var(--primary-green-color);
    }
  }
}
.SubHeaderContainer{
  display: flex;
  align-items: center;
  .sub-btn {
    margin-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    background: var(--primary-green-color);
    border-color: var(--primary-green-color);
    color: var(--white-color);
    border-radius: 5px;
  }
}
.MuiMenu-paper {
  top: 65px !important;
  transition: ease all 0.5s;
}
.MuiMenuItem-root {
  transition: ease all 0.5s;
  a {
    display: flex;
    color: var(--fade-menu-color) !important;
    justify-content: space-around;
    font-size: 0.8rem;
    align-items: center;
    text-decoration: none;
    svg {
      padding-right: 10px;
    }
  }
}
.MuiIconButton-colorInherit {
  color: var(--primary-green-color) !important;
}
.MuiList-padding {
  padding-bottom: 0 !important;
}
.MuiMenuItem-root {
  padding-top: 0 !important;
}
.logout {
  display: flex;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 7px 0;
  color: #fb8b8b;
  font-size: 1rem;
  align-items: center;
  span {
    padding-left: 10px;
  }
  svg {
    font-size: 1rem;
    // padding-right: 10px;
  }
}
@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 50px !important;
  }
}

.makeStyles-toolbar-9 {
  min-height: 0 !important;
}

//sidebar {
.makeStyles-drawerClose-8 {
  width: 47px !important;
}
.makeStyles-drawerClose-208 {
  width: 57px !important;
}

.makeStyles-toolbar-59 {
  min-height: 29px;
}